.photoHeader { 
    margin: 15px;
    display: flex;
    font-size: 28px;
    width: 100%;
}

.pswp img {
    max-width: none;
    object-fit: contain;
}

.galleryWrapper { 
    margin: auto;
    justify-content: center;
}

.uploadButton { 
    display: flex;
    float: right;
    margin-right: 20px;
    margin-left: auto;
}

