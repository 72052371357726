.memoriesHeader { 
    margin: 15px 15px 5px;
    display: flex;
    font-size: 28px;
    width: 100%;
}

.memoriesSynopsis { 
    margin: 0 15px 15px;
    font-size: 16px;
}

.uploadButton { 
    display: flex;
    float: right;
    margin-right: 20px;
    margin-left: auto;
}

.memoryCreator { 
    float: right; 
    display: flex;
}

