.header { 
    display: flex;
    border-bottom: 1px solid #f0f0f0;
}

.title { 
    color: #fcad03;
    display: flex;
    width: 100%;
    font-size: 36px;
    margin-left: 1rem;
}

.titleText { 
    cursor: pointer;
}

.navWrapper { 
    float: right;
    width: 100%;
    direction: rtl;
    // margin-right: 0;
}

@media all and (min-width:0px) and (max-width: 640px) {
    .title { 
        font-size: 16px;
        margin-top: auto;
        margin-bottom: auto;
        cursor: pointer;
    }
    
    .navWrapperText { 
        font-size: 12px;
    }
  }