.messagesHeader { 
    margin: 15px;
    display: flex;
    font-size: 28px;
    width: 100%;
}

.uploadButton { 
    display: flex;
    float: right;
    margin-right: 20px;
    margin-left: auto;
}

.messagesWrapper { 
    display: grid;
}

@media all and (min-width:0px) and (max-width: 640px) {
    .messagesWrapper { 
        display: block;
    }
}
