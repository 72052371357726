.home { 
    display: flex;
    background-image: url(./images/sunset.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    // height: calc(100vmin - 46px);
    height: 100vmin;
    width: auto;
    text-align: center;
}


.homeMain {
    display: flex;
    position: relative;
    width: 70%;
    align-items: center;
    flex-direction: column;
    text-align: center;
    color: white;
    left: 50%;
    top: 50%;
    -ms-transform: translate(-50%, -25%);
    transform: translate(-50%, -25%);
}

.homeTitle {
    color: white;
    font-size: 80px;
    margin-bottom: 0px;
}

.homeTypeIt { 
    font-size: 36px;
    margin-bottom: 20rem;
}

.homeArrow { 
    color: white;
    font-size: 10px; 
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    cursor: pointer;
}

.homeContent { 
    background-color: #fcfce6;
    padding-top: 60px;
    align-items: center;
    flex-direction: column;
    display: flex;
    text-align: center;
}

.intro { 
    width: 80%; 

    display: flex;
    padding-bottom: 30px;
}

.addButton { 
    padding-bottom: 10px;
}

.introText { 
    margin-left: 20px;
    margin-top: auto;
    margin-bottom: auto;
    font-size: 18px;
}

.introTextTitle { 
    font-size: 30px;
    margin-bottom: 15px;
}

.wordcloudWrapper { 
    width: 400px;
    height: 400px;
    border: black solid 0.5px;
    margin-bottom: 30px;
}

.homeImage { 
    height: 400px;
    border: 1px solid #ccc;
}

.donateButtonsWrapper { 
    margin-top: 10px;
    gap: 10px;
    margin-bottom: 10px;
}

.sickKids { 
    margin-right: 10px;
    margin-bottom: 5px;

}

@media all and (min-width:0px) and (max-width: 640px) {
    .homeTitle {
        color: white;
        font-size: 40px;
        margin-bottom: 0px;
    }
    
    .homeTypeIt { 
        font-size: 20px;
        margin-bottom: 20rem;
    }

    .homeArrow { 
        visibility: hidden;
    }

    .homeContent { 
        background-color: #fcfce6;
        padding-top: 30px;
        align-items: center;
        flex-direction: column;
        display: flex;
        text-align: center;
    }

    .intro { 
        width: 80%; 
        display: block;
        padding-bottom: 20px;
    }

    .homeImage { 
        height: 250px;
    }

    .introText { 
        margin-left: 20px;
        margin-top: auto;
        margin-bottom: auto;
        font-size: 14px;
    }

    .introTextTitle { 
        font-size: 26px;
        margin-bottom: 10px;
    }

    .donateText { 
        margin-top: 20px;
        font-size: 12px;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        align-items: center;
        flex-direction: column;
        display: flex;
        text-align: center;
    }
  }